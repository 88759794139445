import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../plugins/axios'

export const getBookmakersRating = createAsyncThunk(
    'bookmakers/rating',
    async () => {
        const response = await axios.get('bookmakersrating')

        return response.data
    }
)
