import { createReducer } from '@reduxjs/toolkit'
import { getBookmakersRating } from './actions'

type StatusAction = 'idle' | 'loading' | 'succeeded' | 'failed'

export type BookmakersState = {
    rating: []
    status: {
        rating: StatusAction
    }
}

const initialState: BookmakersState = {
    rating: [],
    status: {
        rating: 'idle',
    },
}

export const bookmakersReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(getBookmakersRating.pending, (state, action) => {
            state.status.rating = 'loading'
        })
        .addCase(getBookmakersRating.fulfilled, (state, action) => {
            if (action.payload.status == 'ok') {
                state.status.rating = 'succeeded'
                state.rating = action.payload.data.rating
            } else {
                state.status.rating = 'failed'
            }
        })
        .addCase(getBookmakersRating.rejected, (state, action) => {
            state.status.rating = 'failed'
        })
)

export default bookmakersReducer
