import {useEffect, useRef, useState} from 'react';
import {useScreen} from './useScreen';
import { useAppSelector } from './useRedux'

/** Hook gets actual px width of container + listen to window resize */
export const useContainerWidth = () => {
    const containerRef = useRef<HTMLDivElement>(null)
    const [blockWidth, setBlockWidth] = useState(0)

    // Also track sidebar size, for cases when container is in sidebar
    const isSidebarFullSize = useAppSelector((state) => state.app.full)

    const {width} = useScreen()

    useEffect(() => {
        if (!containerRef.current) return
        setBlockWidth(containerRef.current.clientWidth)
    }, [width, isSidebarFullSize]);

    return {
        containerRef,
        blockWidth,
    }
}