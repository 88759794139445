import { createReducer } from '@reduxjs/toolkit'
import { getSurebets, getSurebetMatch } from './actions'

type StatusAction = 'idle' | 'loading' | 'succeeded' | 'failed'

export type SurebetsState = {
    top: []
    match: any
    status: {
        top: StatusAction
        match: StatusAction
    }
}

const initialState: SurebetsState = {
    top: [],
    match: null,
    status: {
        top: 'idle',
        match: 'idle',
    },
}

export const surebetsReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(getSurebets.pending, (state, action) => {
            state.status.top = 'loading'
        })
        .addCase(getSurebets.fulfilled, (state, action) => {
            state.status.top = 'succeeded'
            state.top = action?.payload?.data?.surebets
        })
        .addCase(getSurebets.rejected, (state, action) => {
            state.status.top = 'failed'
        })
        .addCase(getSurebetMatch.pending, (state, action) => {
            state.status.match = 'loading'
        })
        .addCase(getSurebetMatch.fulfilled, (state, action) => {
            state.status.match = 'succeeded'
            state.match = action.payload.data.surebets
        })
        .addCase(getSurebetMatch.rejected, (state, action) => {
            state.status.match = 'failed'
        })
)

export default surebetsReducer
