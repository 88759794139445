import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../plugins/axios'

export const getCompetitionsTop = createAsyncThunk(
    'competitions/top',
    async () => {
        const response = await axios.get('topcompetitions')

        return response.data
    }
)

export const setSeason = createAction('competitions/set-season')