import axios, { AxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'

const axiosInstance = axios.create({
    baseURL: 'https://api.oddsocean.com/v1/',
    headers: {
        'Content-Type': 'application/json',
    },
})

// interception axios
axiosInstance.interceptors.request.use(
    function (config: AxiosRequestConfig) {
        // Do something before request is sent
        // get language

        const lng = localStorage.getItem('lng')
        const token = Cookies.get('auth-token')
        // add languaage
        if (config.url.indexOf('logout') !== -1) {
        } else {
            config.url = config.url + `?language=${lng}`
        }

        config.headers['HTTP_ACCEPT_LANGUAGE'] = lng
        config.headers['X-Auth-Key'] = token
        config.headers['X-Timezone-Offset'] = String(
            -new Date().getTimezoneOffset()
        )

        return config
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error)
    }
)

axiosInstance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error)
    }
)

export default axiosInstance
