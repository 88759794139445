import { RootState } from '../../store'
import { createSelector } from '@reduxjs/toolkit'

export const selectRatingVirtual = (state: RootState) =>
    state.virtualBookmaker.rating

export const virtualBookmakerRatingSelector = createSelector(
    selectRatingVirtual,
    (state) => state
)

export const virtualBookmakerRatingFetchedSelector = createSelector(
    (state: RootState) => state.virtualBookmaker,
        (state) => state
)
