import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../plugins/axios'

export const getSurebets = createAsyncThunk('surebets/top', async () => {
    const response = await axios.get('surebets')

    return response.data
})

export const getSurebetMatch = createAsyncThunk(
    'surebets/match',
    async (id: string) => {
        const response = await axios.get(`surebets/${id}`)

        return response.data
    }
)
