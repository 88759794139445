import Footer from './partials/Footer'
import Header from './partials/Header'
import Script from 'next/script'
import { Fragment, lazy, useEffect, useState } from 'react'
import { useAppSelector } from '../hooks'
import { BlockedCountry } from './BlockedCountry'
import { useTranslation } from 'next-i18next'
import { useGetTopCompetitionsQuery } from '../api'

export default function Layout({
    children,
    isBlockedCountry,
    hideFooter,
}: any) {
    const { i18n } = useTranslation()

    const [AuthModalComponent, setAuthModalComponent] = useState(null)
    const open = useAppSelector((state) => state.app.authModal)

    const {
        isFetching: areCompetitionsFetching,
        data,
    } = useGetTopCompetitionsQuery(
        { locale: i18n.language },
        { skip: false }
    )
    const competitions = data?.data?.competitions || []

    useEffect(() => {
        if (open) {
            const component = lazy(() => import('./AuthModal'))
            setAuthModalComponent(component)
        }
    }, [open])

    return (
        <div className='layout'>
            <Script src='https://www.googletagmanager.com/gtag/js?id=G-BF74T7HJCH' />
            <Script id='google-analytics'>
                {
                    'window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'G-BF74T7HJCH\');'
                }
            </Script>
            {isBlockedCountry
                ? <BlockedCountry />
                : <Fragment>
                    <Header competitionsTop={areCompetitionsFetching ? [] : competitions} />
                    <main className='layout__content'>{children}</main>
                    {!hideFooter && (
                        <Footer competitionsTop={competitions} />
                    )}
                    {AuthModalComponent && <AuthModalComponent />}
                </Fragment>
            }
        </div>
    )
}