import {createSlice} from '@reduxjs/toolkit';
import {HYDRATE} from 'next-redux-wrapper';
import {RootState} from '../../store';
import {MatchesTimePeriodValue, MatchesType} from '../../api';
import {getDateFormatted} from '../../utils';

/** Filters for matches list in HomePage.
 * Use createSlice instead of base reducer, as filter needs to be init from SSR,
 * base reducer doesn't allow such operation (see extraReducers field below). */
const FiltersSlice = createSlice({
    name: 'filters',
    initialState: {
        matchesType: 'all' as MatchesType,
        date: '',
        time: null as MatchesTimePeriodValue,
    },
    reducers: {
        setFilterDate: (state, {payload}) => {
            state.date = payload
            if (state.time) state.time = null

            if (state.matchesType === 'all') return
            // Reset type, if current type won't be displayed for new date
            const today = getDateFormatted()
            if (
                (state.matchesType === 'live' && payload !== today)
                || (state.matchesType === 'upcoming' && payload < today)
                || (state.matchesType === 'finished' && payload > today)
            ) state.matchesType = 'all'
        },
        setFilterTime: (state, {payload}) => {
            state.time = payload
        },
        setFilterMatchesType: (state, {payload}) => {
            state.matchesType = payload
        },
        resetMatchesFilters: (state) => {
            state.matchesType = 'all'
            state.date = getDateFormatted()
            state.time = null
        },
    },
    // Init values from SSR
    extraReducers: {
        [HYDRATE]: (state, action) => {
            const {
                matchesType,
                date,
                time,
            } = action.payload.filters

            state.date = date || getDateFormatted()
            state.matchesType = matchesType
            state.time = time
        },
    },
});

export const {
    setFilterDate,
    setFilterTime,
    setFilterMatchesType,
    resetMatchesFilters,
} = FiltersSlice.actions;

export const selectFilterMatchesType = (state: RootState) => state.filters.matchesType;
export const selectFilterDate = (state: RootState) => state.filters.date;
export const selectFilterTime = (state: RootState) => state.filters.time;

export const {reducer: filtersReducer} =  FiltersSlice;