import Image from 'next/image'
import logo from '../../public/img/logo.svg'
import st from './Footer.module.scss'

import eighteen from '../../public/img/eighteen.svg'
import gamble from '../../public/img/gamble.svg'

import LanguageSwitcher from '../LanguageSwitcher'

// import telegram from '../../public/img/telegram_ico.svg'
// import tw from '../../public/img/tw-ico.svg'
// import vk from '../../public/img/vk-ico.svg'

import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { CompetitionTop, useGetBonusQuery } from '../../api'
import {BookmakerLinkRedirect} from '../BookmakerLinkRedirect';
import { Logo } from '../logo'
import { APP_ROUTES } from '../../const'
import { getDateFormatted } from '../../utils'
import NoSsr from '../NoSsr'
import { FU } from '../../types'
import clsx from 'clsx'

type Props = {
    competitionsTop: CompetitionTop[],
}

const helpItems = ['faq', 'ads', 'feedback']

//@todo Add socials
// const socials = [
//     { name: 'telegram', image: telegram },
//     { name: 'twitter', image: tw },
//     { name: 'vk', image: vk },
// ]

export default function Footer({ competitionsTop }: Props) {
    const { t, i18n } = useTranslation('common')
    const bonus = useGetBonusQuery(i18n.language)
    return (
        <footer className='footer'>
            <div className={st.containerMain}>
                <div className={st.containerWithPadding}>
                    <Image
                        src={logo}
                        alt='OddsOcean.com'
                        loading='lazy'
                    />
                </div>
                <div className={st.containerLinks}>
                    <NoSsr>
                        <UrlsMain />
                    </NoSsr>
                    <Column title={t('footer.popular_events')}>
                        {competitionsTop.map((item, index) => (
                            <li key={index}>
                                <Link
                                    className={st.linkItem}
                                    href={item.url}
                                >
                                    <Image
                                        className='button__ico-filter-footer'
                                        src={`https://i.oddsocean.com/img/ocean/60x60/compbw/${item.logo}.png`}
                                        width={20}
                                        height={20}
                                        alt='logo'
                                    />
                                    {item.title}
                                </Link>
                            </li>
                        ))}
                    </Column>
                    <Column title={t('footer.help_contact')}>
                        {helpItems.map((item) => (
                            <li key={item}>
                                <a
                                    className={st.linkItem}
                                    href='#'
                                >
                                    {t(`footer.${item}`)}
                                </a>
                            </li>
                        ))}
                    </Column>
                    <Column title={t('footer.bonuses')}>
                        {/*@todo Add loader*/}
                        {!bonus.isFetching && bonus.data.data.bonuses.map((bonus, index) => (
                            <li
                                className={st.linkTopBonusContainer}
                                key={index}
                            >
                                <BookmakerLinkRedirect
                                    className={clsx(st.linkTopBonus)}
                                    bonusId={bonus.id}
                                    bookmakerLogoId={bonus.bookmaker.logo_id}
                                    bookmakerColor={bonus.bookmaker.color}
                                >
                                    <div className={st.bonusLogoContainer}>
                                        <Logo
                                            type='bookmaker_white'
                                            size={80}
                                            height={20}
                                            logoId={bonus.bookmaker.logo_id}
                                        />
                                        <Logo
                                            className={st.bonusLogoColored}
                                            type='bookmaker_original'
                                            size={80}
                                            height={20}
                                            logoId={bonus.bookmaker.logo_id}
                                        />
                                    </div>
                                    <span className={st.bonusTextLong}>
                                        {bonus.text.long}
                                    </span>
                                    <span className={st.bonusTextShort}>
                                        {bonus.text.short}
                                    </span>
                                    <div
                                        className={st.bonusOverlay}
                                        style={{ background: '#' + bonus.bookmaker.color }}
                                    />
                                </BookmakerLinkRedirect>
                            </li>
                        ))}
                    </Column>
                </div>
                <div className='container'>
                    <div className='footer__bottom'>
                        <div className='flex flex-align grid grid--16'>
                            <div className='badge badge--footer-18'>
                                <Image src={eighteen} alt='eighteen' />
                            </div>
                            <div className='flex  flex-column'>
                                <Image src={gamble} alt='gamble' />
                                <span className='footer__title-bottom'>
                                    © 2024 ODDSOCEAN.COM – {t('footer.copyright')}
                                </span>
                            </div>
                        </div>
                        <div className='flex flex-align grid grid--8 footer__wr'>
                            <button className='button button--rounded button--rating grid grid--4 button--footer'>
                                {t('footer.privacy_policy')}
                            </button>
                            <button className='button button--rounded button--rating grid grid--4 button--footer'>
                                {t('footer.terms_of_use')}
                            </button>
                            <LanguageSwitcher
                                direction='up'
                                class='lg-footer-bottom'
                            />
                        </div>
                    </div>
                    <div className='flex flex-align footer__mobile'>
                        <span className='footer__title-bottom footer__title-bottom--main'>
                            © 2024 ODDSOCEAN.COM – {t('footer.copyright')}
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    )
}

type ColumnProps = {
    title: string,
}
const Column: FU<ColumnProps> = function({ children, title }) {
    return (
        <div className={st.columnLinks}>
            <div className={st.containerWithPadding}>
                <span className={st.columnTitle}>
                    {title}
                </span>
            </div>
            <ul className='flex flex-column'>
                {children}
            </ul>
        </div>
    )
}

function UrlsMain() {
    const { t } = useTranslation('common')

    const today = getDateFormatted()
    const urlsMain = [
        {
            labelKey: 'footer.bets',
            url: APP_ROUTES.MAIN,
        },
        {
            labelKey: 'footer.live_odds',
            url: `/football/${today}/live`,
        },
        {
            labelKey: 'footer.surebets',
            url: APP_ROUTES.SUREBETS,
        },
        {
            //@todo Translate
            label: 'Football Betting',
            url: `/football/${today}/upcoming`,
        },
        {
            labelKey: 'global.betting_contest',
            url: APP_ROUTES.BETTING_CONTEST,
        },
        {
            //@todo Translate
            label: 'FIFA Ranking',
            url: APP_ROUTES.FIFA_RANKING,
        },
    ]

    return (
        <Column title={t('footer.top_pages')}>
            {urlsMain.map((item, index) => (
                <li key={index}>
                    <Link
                        className={st.linkItem}
                        href={item.url}
                    >
                        {item.labelKey ? t(item.labelKey) : item.label}
                    </Link>
                </li>
            ))}
        </Column>
    )
}
