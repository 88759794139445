import React from 'react'

//@todo Replace multiple fields on objects = easy props pass
type Props = {
    children: React.ReactNode,
    className?: string,
    bonusId?: number,
    bookmakerId?: number,
    bookmakerLogoId?: number,
    bookmakerColor?: string,
    matchId?: number,
    oddType?: string,
    oddSelect?: string,
    period?: string,
    handicap?: number,
}

export const BookmakerLinkRedirect = ({
    bonusId,
    bookmakerId,
    bookmakerLogoId,
    bookmakerColor,
    children,
    matchId,
    oddType,
    oddSelect,
    period,
    handicap,
    className,
}: Props) => {
    const queryParams = new URLSearchParams('')

    if (bonusId) queryParams.set('bonusId', String(bonusId))
    if (bookmakerId) queryParams.set('bookmakerId', String(bookmakerId))
    if (bookmakerLogoId) queryParams.set('bookmakerLogoId', String(bookmakerLogoId))
    if (bookmakerColor) queryParams.set('bookmakerColor', bookmakerColor)
    if (matchId) queryParams.set('matchId', String(matchId))
    if (oddType) queryParams.set('oddType', oddType)
    if (oddSelect) queryParams.set('oddSelect', oddSelect)
    if (period) queryParams.set('period', period)
    if (handicap) queryParams.set('handicap', String(handicap))

    return (
        <a
            className={className}
            href={`/redirect?${queryParams.toString()}`}
            target='_blank'
            rel='noreferrer'
        >
            {children}
        </a>
    )
}
