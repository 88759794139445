import {BaseQueryApi} from '@reduxjs/toolkit/query';
import { MatchesTimePeriodValue } from './types'

/** Custom query to pass hash in url from store.
 * Why you can't pass hash as direct param? Read in ticket {@link http://181.215.69.112:8080/browse/OCEAN-272} */
export const buildQueryWithHashParam = async (
    {
        url,
        method,
        keepUnusedDataFor,
    }: {
        url: string,
        method: string,
        keepUnusedDataFor?: number,
    },
    reducerPath: string,
    queryName: string,
    _api: BaseQueryApi,
    baseQuery: any
) => {
    const state = _api.getState() as any
    const queriesKeys = Object.keys(state[reducerPath].queries).filter(
        (key) => key.includes(queryName + '(')
    )
    // You need to get the newest query from store for each response, newest is the last
    const query = state[reducerPath].queries[queriesKeys[queriesKeys.length - 1]] as any

    let hash = ''
    if (query.data) hash = (query.data as any).hash

    const fetchedData = await baseQuery({
        url: hash
            ? url + `&hash=${hash}`
            : url,
        method: method,
        keepUnusedDataFor,
    })

    return {
        data: fetchedData.error
            ? query.data
            : fetchedData.data,
    }
}

/** Format time string from "next-X-hours" to "Xh"  */
export function formatMatchesFilerTimeForServer(time: MatchesTimePeriodValue) {
    return time
        ? time.split('-')[1] + 'h'
        : null
}