import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../plugins/axios'

export const getRating = createAsyncThunk(
    'virtual-bookmaker/rating',
    async () => {
        const response = await axios.get('makerrating')

        return response.data
    }
)
