import { RootState } from '../../store'
import { createSelector } from '@reduxjs/toolkit'

export const selectTopSurebets = (state: RootState) => state.surebets.top
export const selectMatchSurebets = (state: RootState) => state.surebets.match

export const topSurebetsSelector = createSelector(
    selectTopSurebets,
    (state) => state
)

export const topSurebetsFetchedSelector = createSelector(
    (state: RootState) => state.surebets,
    (state) => state
)

export const matchSurebetsSelector = createSelector(
    selectMatchSurebets,
    (state) => state
)
