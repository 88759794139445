import { RootState } from '../../store'
import { createSelector } from '@reduxjs/toolkit'

export const selectResultsTeamStatus = (state: RootState) =>
    state.resultsTeam.status
export const selectResultsTeamData = (state: RootState) => state.resultsTeam

export const resultsTeamStatusSelector = createSelector(
    selectResultsTeamStatus,
    (state) => state
)

export const resultsTeamDataSelector = createSelector(
    selectResultsTeamData,
    (state) => state
)
