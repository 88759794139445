import React from 'react';
import {i18n} from 'i18next';
import {NextRouter} from 'next/router';

// Update page url.
// For example, after filters or locale change (replace football -> futbol)
export const useUrlLocaleUpdate = (
    url: string | undefined,
    router: NextRouter,
    i18n: i18n,
    preventUpdate = false,
) => {
    React.useEffect(() => {
        if (preventUpdate || !url || (url === router.asPath)) return

        let urlReplaceTo = url

        // Keep anchor, if url's been changed
        if (router.asPath.includes('#')) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [urlString, anchorTag] = router.asPath.split('#')
            urlReplaceTo += '#' + anchorTag
            if (urlReplaceTo === router.asPath) return
        }

        router.replace(
            urlReplaceTo,
            undefined,
            {
                locale: i18n.language,
                shallow: true,
                scroll: true,
            }
        )
    }, [url])
}