import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../plugins/axios'

type PayloadMatches = {
    sport: string
    oddType: string
    type: string
    date: string
    competitionId?: string
}

export type PayloadOdds = {
    id: string
    type: string
    odd_type?: string
    period?: number
    handicap?: string | number
}

export const setTypeBlock = createAction('change-type')

export const getForecasts = createAsyncThunk(
    'matches/predictions',
    async (id: string) => {
        const response = await axios.get(`predictions/${id !== '' ? id : ''}`)
        return response.data
    }
)

export const getMatchEvents = createAsyncThunk(
    'matches/match-events',
    async (id: string) => {
        const response = await axios.get(`matchincidents/${id}`)
        return response.data
    }
)

export const getMatchDetails = createAsyncThunk(
    'matches/match-details',
    async (id: string) => {
        const response = await axios.get(`matchdetails/${id}`)
        return response.data
    }
)

export const getMatch = createAsyncThunk(
    'matches/match',
    async (id: string) => {
        const response = await axios.get(`match/${id}`)
        return response.data
    }
)
export const saveMatch = createAction<any>('matches/match-save')
export const selectMatchId = createAction<number>('matches/match-select')
export const resetMatchIdSelection = createAction('matches/match-selection-reset')

export const getMatchOdds = createAsyncThunk(
    'matches/odds',
    async (payload: PayloadOdds) => {
        let addUrl = ''
        if (payload.odd_type) addUrl += `/${payload.odd_type}/${payload.period}`

        if (payload.odd_type && payload.handicap)
            addUrl += `/${payload.handicap}`

        const response = await axios.get(
            `odds/${payload.id}/${payload.type}${addUrl}`
        )
        return response.data
    }
)

export const getMatchday = createAsyncThunk('matches/match-day', async () => {
    const response = await axios.get('match/')
    return response.data
})

export const getMatches = createAsyncThunk(
    'matches/all',
    async ({
        sport = '1',
        oddType = '3w',
        type = 'all',
        date = '2023-04-19',
    }: PayloadMatches) => {
        const response = await axios.get(
            `matches/${sport}/${oddType}/${type}/${date}/`
        )

        return response.data
    }
)

export const getOddsPopup = createAsyncThunk(
    'matches/oddspopup',
    async ({
        match_id = '9013711',
        odd_type = '3w',
        odd_select = 'x',
        period =  null,
        handicap = null,
    }: any) => {
        const response = await axios.post(
            'oddspopup',
            {
                match_id,
                odd_type,
                odd_select,
                period,
                handicap,
            }
        )
        return response.data
    }
)

export const getMatchesCompetition = createAsyncThunk(
    'matches/competition',
    async ({
        sport = '1',
        oddType = '3w',
        type = 'all',
        date = '2023-04-19',
        competitionId = null,
    }: PayloadMatches) => {
        const response = await axios.get(
            `matches/${sport}/${oddType}/${type}/${date}/${competitionId}`
        )

        return response.data
    }
)

export const getMatchStandings = createAsyncThunk(
    'match/standings',
    async (id: string) => {
        const response = await axios.get(`matchstandings/${id}`)
        return response.data
    },
)
