export function getVisibleSectionIndex(sections: Record<string, HTMLDivElement>): string {
    const scrollPosition = window.scrollY
    let keyActive = ''

    Object.keys(sections).every((key) => {
        const section = sections[key]

        if (!section) return true

        const sectionTop = section.offsetTop
        const sectionBottom = sectionTop + section.offsetHeight

        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
            keyActive = key
            return false
        }

        return true
    })

    return keyActive
}
