import React, { createContext, useContext, useReducer, ReactNode } from 'react';

interface State {
  eventOccurred: boolean;
}

interface Action {
  type: string;
}

interface ContextProps {
  state: State;
  dispatch: React.Dispatch<Action>;
}

const EventContext = createContext<ContextProps | undefined>(undefined);

const initialState: State = {
  eventOccurred: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, eventOccurred: true };
    default:
      return state;
  }
};

export const EventProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <EventContext.Provider value={{ state, dispatch }}>
      {children}
    </EventContext.Provider>
  );
};

export const useEvent = (): ContextProps => {
  const context = useContext(EventContext);

  return context;
};