import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../plugins/axios'

interface ResultsTeamPayload {
    team1Id?: number
    team2Id?: number
    type?: string
}

export const getFirstTeamMatchResults = createAsyncThunk(
    'resultsteamFirst/match',
    async ({ team1Id, type }: ResultsTeamPayload) => {
        const response = await axios.get(
            `resultsteam/${type || 'all'}/${team1Id}`
        )
        return response.data
    }
)

export const getSecondTeamMatchResults = createAsyncThunk(
    'resultsteamSecond/match',
    async ({ team2Id, type }: ResultsTeamPayload) => {
        const response = await axios.get(
            `resultsteam/${type || 'all'}/${team2Id}`
        )
        return response.data
    }
)

export const getBothTeamsMatchResults = createAsyncThunk(
    'resultsteamBoth/match',
    async ({ team1Id, team2Id, type }: ResultsTeamPayload) => {
        const response = await axios.get(
            `resultsteam/${type || 'all'}/${team1Id}/${team2Id}`
        )
        return response.data
    }
)

export const clearDataInStore = createAsyncThunk(
    'resultsteamClear/match',
    async() => {
        return null
    }
)