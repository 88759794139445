import { RootState } from '../../store'
import { createSelector } from '@reduxjs/toolkit'

export const selectCompetitionsTop = (state: RootState) =>
    state.competitions.top

export const competitionsTopSelector = createSelector(
    selectCompetitionsTop,
    (state) => state
)
