export function calculateSliderWidth(block_el: any): number {
    let widthSidebar: number = 0
    if (window.innerWidth < 1020 && window.innerWidth > 860) {
        widthSidebar = window.innerWidth - (240 + 12 + 12)
    } else {
        widthSidebar =
            window.innerWidth < 786
                ? window.innerWidth - 12
                : block_el?.current.clientWidth - 12
    }
    return widthSidebar
}