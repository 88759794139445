import React, { useRef, useState } from 'react'
import Image from 'next/image'
import { Scrollbars } from 'react-custom-scrollbars'
import useOutsideAlerter from '../hooks/outsideClick'
import search from '../public/img/search.svg'
import closeIco from '../public/img/close.svg'
import ava from '../public/img/people.png'
import ava8 from '../public/img/ava8.png'
import { useTranslation } from 'react-i18next'

type Props = {
    // Add custom props here
    onToggle: Function
}

export default function SearchBox(props: Props) {
    const [open, setOpen] = useState(false)
    const [text, setText] = useState('')

    const { t } = useTranslation('common')

    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef, () => {
        close()
    })

    const close = () => {
        setOpen(false)
        props.onToggle(false)
        setText('')
    }

    const toggle = () => {
        setOpen(!open)
        props.onToggle(!open)
    }

    const handleChange = (event: any) => {
        setText((event.target as HTMLInputElement).value)
    }

    return (
        <div
            className="wrapper"
            style={{
                justifyContent: 'flex-end',
                width: '100%',
                display: 'flex',
            }}
        >
            <button
                onClick={toggle}
                className="button button--bound button--small-size button--transparent-color"
            >
                <Image src={search} alt="search" />
            </button>
            <div
                ref={wrapperRef}
                className={`header__search ${
                    open ? 'header__search--open' : ''
                }`}
            >
                <Image
                    src={search}
                    loading="eager"
                    alt="search"
                    width={20}
                    height={20}
                    className="header__ico-search header__ico-search--left"
                />
                <input
                    type="text"
                    placeholder={t('header.search')}
                    className="header__search-input"
                    onChange={handleChange}
                />
                <button
                    onClick={close}
                    className="button button--bound button--small-size button--transparent-color"
                >
                    <Image
                        src={closeIco}
                        alt="close"
                        width={20}
                        height={20}
                        className="header__ico-search header__ico-search--right"
                    />
                </button>
                {text.length > 0 && (
                    <div className="search-result">
                        <Scrollbars
                            universal={true}
                            autoHide
                            style={{ height: '100%', width: '100%' }}
                        >
                            <div style={{ paddingRight: 8 }}>
                                <ul className="list">
                                    {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                                        <li
                                            key={`list-item-result-${i}`}
                                            className="list__item list__item--search-result"
                                        >
                                            <a
                                                className="list__link list__link--search-result"
                                                href="#"
                                            >
                                                <div className="wrapper">
                                                    <Image
                                                        src={ava}
                                                        alt="ava"
                                                        className="list__ico list__ico--search-result"
                                                        width={40}
                                                        height={40}
                                                    />
                                                    <Image
                                                        src={ava8}
                                                        alt="ava8"
                                                        className="list__ico list__ico--search-result list__ico--flag"
                                                        width={20}
                                                        height={20}
                                                    />
                                                </div>
                                                <div className="flex flex-column grid grid--4">
                                                    <span>
                                                        UEFA Лига Чемпионов
                                                    </span>
                                                    <div className="flex grid grid--8 flex-align list__subtitle">
                                                        Селтик
                                                        <div className="circle-ico"></div>
                                                        Футбол
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Scrollbars>
                    </div>
                )}
            </div>
        </div>
    )
}
