import logo from '../public/img/logotype.svg'
import Image from 'next/image'
import { useCustomTranslation } from '../hooks/useCustomTranslation'

export const BlockedCountry = () => {
    const { t } = useCustomTranslation('common')
    return (
        <div className="blocked-country">
            <Image src={logo} alt='OddsOcean' height={50} />
            <p>{t('blocked.title')}</p>
        </div>
    )
}