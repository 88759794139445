import React from 'react'
import { useState, useRef } from 'react'
import * as Portal from '@radix-ui/react-portal';
import clsx from 'clsx';
import useOutsideAlerter from '../hooks/outsideClick'
import Image from 'next/image'
import { Scrollbars } from 'react-custom-scrollbars'

type Option = {
    value: string
    title: string
    ico?: any
}

const calcAbsoluteCoordsForAnchor = (anchorRef: HTMLDivElement) => {
    if (!anchorRef) {
        return {
            top: 0,
            left: 0,
            width: 0,
        }
    }

    const {top, left, width, height} = anchorRef.getBoundingClientRect()
    return {
        top: window.scrollY + top + height,
        left,
        width,
    }
}

type Props = {
    // Add custom props here
    options?: Option[]
    optionsSub?: Option[]
    className?: string
    classNameList?: string
    value?: string
    valueSub?: string
    header?: any
    onChange?: Function
    onChangeSub?: Function
    disabled?: boolean
    all?: string
    // Render dropdown list in Portal, useful in containers with overflow: scroll
    useAbsolutePosition?: boolean,
    // Set dropdown width to 100% of its container
    fitContainerWidth?: boolean,
}

export default function Dropdown(props: Props) {
    const {
        options,
        optionsSub = [],
        className,
        value,
        header,
        valueSub,
        disabled = false,
        all = 'все',
        onChange,
        onChangeSub,
        useAbsolutePosition,
    } = props

    const wrapperRef = useRef<HTMLDivElement>(null)
    const anchorRef = useRef<HTMLDivElement>(null)
    const dropdownListRef = useRef<HTMLDivElement>(null)

    const [open, setOpen] = useState(false)

    useOutsideAlerter(
        wrapperRef,
        () => setOpen(false),
        dropdownListRef,
    )

    const onToggle = () => {
        if (disabled) return
        setOpen(!open)
        return false
    }

    function _onChange(option: Option) {
        onChange(option)
        setOpen(false)
    }

    function _onChangeSub(option: Option) {
        onChangeSub(option)
        setOpen(false)
    }

    function getTitle() {
        for (let i = 0; i < options.length; i++) {
            if (options[i].value == value) {
                return options[i].title
            }
        }
        return all
    }

    function getTitleSub() {
        for (let i = 0; i < optionsSub.length; i++) {
            if (optionsSub[i].value == valueSub) {
                return optionsSub[i].title
            }
        }
    }

    return (
        <div
            className={clsx(
                'dropdown',
                useAbsolutePosition && 'dropdown--with-abs-list',
                className
            )}
            ref={wrapperRef}
        >
            <div
                ref={anchorRef}
                className={clsx('dropdown__header', open && 'dropdown__header--active')}
                onClick={onToggle}
            >
                {React.cloneElement(header, {
                    title: getTitle(),
                    subtitle: getTitleSub(),
                })}
            </div>
            {useAbsolutePosition
                ? (open &&
                    <Portal.Root>
                        <DropdownList
                            {...props}
                            ref={dropdownListRef}
                            open={open}
                            anchorRef={anchorRef}
                            _onChange={_onChange}
                            _onChangeSub={_onChangeSub}
                            />
                    </Portal.Root>
                )
                : (
                    <DropdownList
                        {...props}
                        ref={dropdownListRef}
                        open={open}
                        _onChange={_onChange}
                        _onChangeSub={_onChangeSub}
                        anchorRef={anchorRef}
                    />
                )
            }
        </div>
    )
}

type ListProps = {
    open: boolean,
    _onChange: (option: Option) => void,
    _onChangeSub: (option: Option) => void,
    anchorRef: React.MutableRefObject<HTMLDivElement>
} & Props

const DropdownList =  React.forwardRef(function List({
    options,
    optionsSub = [],
    classNameList,
    value,
    _onChange,
    _onChangeSub,
    open,
    anchorRef,
    useAbsolutePosition,
    fitContainerWidth,
}: ListProps,
ref: any) {
    const MAX_HEIGHT = 300
    const listInitialHeight = 40 * options.length + 16

    const {top, left, width} = calcAbsoluteCoordsForAnchor(anchorRef.current)
    return (
        <div
            ref={ref}
            className={clsx(
                'dropdown__list',
                open && 'dropdown__list--open',
                classNameList
            )}
            style={{
                height: listInitialHeight > MAX_HEIGHT
                    ? MAX_HEIGHT
                    : listInitialHeight,
                top: useAbsolutePosition ? top : undefined,
                left: useAbsolutePosition ? left : undefined,
                width: fitContainerWidth ? width : undefined,
            }}
        >
            <Scrollbars
                universal={true}
                autoHide
                style={{height: '100%', width: '100%'}}
            >
                <ul className="dropdown__list-wrapper">
                    {options.map((option, index) => (
                        <li
                            key={`list-item-${index}`}
                            className={clsx(
                                'dropdown__list-item',
                                option.value == value && 'dropdown__list-item--active'
                            )}
                            onClick={() => _onChange(option)}
                        >
                            {option.ico && (
                                <Image
                                    src={option.ico}
                                    alt="football"
                                    width={20}
                                    height={20}
                                    className={`button__ico-filter`}
                                />
                            )}
                            <span>{option.title}</span>
                        </li>
                    ))}
                </ul>
            </Scrollbars>
            {(value == 'ah' || value == 'totals') &&
                optionsSub.length > 0 && (
                    <div className="dropdown__sub-list">
                        <Scrollbars>
                            <ul className="dropdown__list-wrapper">
                                {optionsSub.map((option, index) => (
                                    <li
                                        key={`list-item-sub-${index}`}
                                        className={clsx(
                                            'dropdown__list-item',
                                            option.value == value && 'dropdown__list-item--active'
                                        )}
                                        onClick={() => _onChangeSub(option)}
                                    >
                                        {option.ico && (
                                            <Image
                                                src={option.ico}
                                                alt="football"
                                                width={20}
                                                height={20}
                                                className={`button__ico-filter`}
                                            />
                                        )}
                                        <span>{option.title}</span>
                                    </li>
                                ))}
                            </ul>
                        </Scrollbars>
                    </div>
                )}
        </div>
    )
})
