import {MatchPlayoffStage} from './match-playoff';
import {LanguageParam} from './request';
import {TeamInMatch} from './match';
import {MatchLegend, MatchStandingsGroupItem} from './match-standings';

export type CompetitionUrlPart = {
    type: 'gender' | 'country' | 'competition',
    title: string,
    id: number,
    url: string,
}

export type Competition = {
    competition: CompetitionUrlPart[],
    logo: string,
    logo_bw: string,
    seasons: {
        index: string,
        title: string,
    }[],
    season: string,
    rounds: {
        index: string,
        title: string,
    }[],
    menu: {
        index: CompetitionMenuIndex,
        title: string,
    }[],
}
export type CompetitionFetchParams = {
    id: number,
    season: string,
} & LanguageParam

export type CompetitionMenuIndex = 'matches' | 'fixtures' | 'media' | 'stat' | 'playoff'

export const CompetitionMenusArray: readonly CompetitionMenuIndex[] = [
    'matches', 'fixtures', 'media', 'stat', 'playoff',
] as const

/** Playoff in competition */

export type CompetitionPlayoff = {
    title: string,
    playoff: MatchPlayoffStage[] | false,
}

export type CompetitionPlayoffs = {
    playoffs: CompetitionPlayoff[]
}

/** List of countries and related competitions */

export type Country = {
    id: number,
    title: string,
    flag: number,
    top: boolean, // Display/hide country on initial render
}

export type CountriesCompetitions = {
    countries: Country[],
}

/** List of competitions in selected country */

export type CountryCompetitions = {
    competitions: {
        id: number,
        gender: string,
        title: string,
        url: string,
    }[],
}
export type CountryCompetitionsFetchParams = {
    sport: number,
    country: number,
} & LanguageParam

/** Standings in competition */

export type CompetitionStandings = Array<{
    groups: {
        group_title: string,
        table: MatchStandingsGroupItem[],
        matches: CompetitionStandingsMatch[],
    }[],
    legend: MatchLegend[],
}>

export type CompetitionStandingsMatch = {
    id: number,
    url: string,
    date_start: number,
    live: boolean,
    status: string,
    team1: TeamInMatch,
    team2: TeamInMatch,
    score: {
        livetime: string[] | false,
        result: number[] | null,
    },
    next_round: number | null,
    odds: {
        display: string,
        won: boolean,
    }[] | null,
}

export type CompetitionStandingsFetchParams = {
    competitionId: number,
    season: string,
} & LanguageParam