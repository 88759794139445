import React from 'react'
import { ReactNode, useEffect, useState} from 'react'

export interface IScreenContext {
    width: number,
    height: number,
    isPhone: boolean,
    isTablet: boolean,
    isDesktop: boolean,
}

export const SCREEN_PHONE = 'phone'
export const SCREEN_TABLET = 'tablet'
export const SCREEN_DESKTOP = 'desktop'
const media = {
    [SCREEN_PHONE]: 320,
    [SCREEN_TABLET]: 768,
    [SCREEN_DESKTOP]: 1024,
}

export const ScreenContext = React.createContext({
    width: 0,
    height: 0,
    isPhone: false,
    isTablet: false,
    isDesktop: false,
} as IScreenContext)

export const ScreenProvider = (props: { children: ReactNode }) =>  {
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)

    const setWindowDimensions = () => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
    }

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null
        const onResize = () => {
            if (timer) {
                clearTimeout(timer)
            }
            timer = setTimeout(() => {
                setWindowDimensions()
            }, 400)
        }

        setWindowDimensions()
        window.addEventListener('resize', onResize, false)

        return () => {
            if (timer) clearTimeout(timer)
            window.removeEventListener('resize', onResize)
        }
    }, [])

    const {
        isPhone,
        isTablet,
        isDesktop,
    } = React.useMemo(() => {
        function getDeviceType() {
            if (width < media[SCREEN_TABLET]) return SCREEN_PHONE
            if (width < media[SCREEN_DESKTOP]) return SCREEN_TABLET
            return SCREEN_DESKTOP
        }

        return {
            isPhone: getDeviceType() === SCREEN_PHONE,
            isTablet: getDeviceType() === SCREEN_TABLET,
            isDesktop: getDeviceType() === SCREEN_DESKTOP,
        }
    }, [width])

    const value = {
        width,
        height,
        isPhone,
        isTablet,
        isDesktop,
    }

    return (
        <ScreenContext.Provider value={value as IScreenContext}>
            {props.children}
        </ScreenContext.Provider>
    )
}
