import { createReducer } from '@reduxjs/toolkit'
import {
    getSports,
    getCountries,
    getCompetitionsByCountry,
    setAuthModal,
    getFavorites,
    setFavorites,
    addFavorites,
    removeFavorites,
    setFull,
    getRedirectUrl,
    setModal,
    saveRouteChange,
} from './actions'

type StatusAction = 'idle' | 'loading' | 'succeeded' | 'failed'

export type AppState = {
    route: {
        prev: string,
    },
    sports: []
    countries: any[]
    authModal: boolean
    favorites: any
    goLink: any
    status: {
        sports: StatusAction
        countries: StatusAction
        competitions_country: StatusAction
        favorites: StatusAction
        addFavorite: StatusAction
        removeFavorite: StatusAction
        go: StatusAction
    }
    full: boolean,
    bestOfferModal: any,
}

const initialState: AppState = {
    route: {
      prev: '',
    },
    sports: [],
    countries: [],
    favorites: {
        team: [],
        match: [],
        competition: [],
        bookmaker: [],
    },
    bestOfferModal: false,
    authModal: false,
    goLink: null,
    status: {
        addFavorite: 'idle',
        removeFavorite: 'idle',
        sports: 'idle',
        go: 'idle',
        countries: 'idle',
        competitions_country: 'idle',
        favorites: 'idle',
    },
    full: false,
}

export const appReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(saveRouteChange, (state, action) => {
            state.route.prev = action.payload
        })
        .addCase(getRedirectUrl.pending, (state, action) => {
            state.status.go = 'loading'
        })
        .addCase(getRedirectUrl.fulfilled, (state, action) => {
            if (action.payload.status == 'ok') {
                state.status.go = 'succeeded'
                state.goLink = action.payload.data
            } else {
                state.status.go = 'failed'
            }
        })
        .addCase(getRedirectUrl.rejected, (state, action) => {
            state.status.go = 'failed'
        })
        .addCase(getSports.pending, (state, action) => {
            state.status.sports = 'loading'
        })
        .addCase(getSports.fulfilled, (state, action) => {
            if (action.payload.status == 'ok') {
                state.status.sports = 'succeeded'
                state.sports = action.payload.data.sports
            } else {
                state.status.sports = 'failed'
            }
        })
        .addCase(getSports.rejected, (state, action) => {
            state.status.sports = 'failed'
        })
        .addCase(getCountries.pending, (state, action) => {
            state.status.countries = 'loading'
        })
        .addCase(getCountries.fulfilled, (state, action) => {
            if (action.payload.status == 'ok') {
                state.status.countries = 'succeeded'
                state.countries = action.payload.data.countries
            } else {
                state.status.countries = 'failed'
            }
        })
        .addCase(getCountries.rejected, (state, action) => {
            state.status.countries = 'failed'
        })
        .addCase(getCompetitionsByCountry.pending, (state, action) => {
            state.status.competitions_country = 'loading'
        })
        .addCase(getCompetitionsByCountry.fulfilled, (state, action) => {
            if (action.payload.data.status == 'ok') {
                state.status.competitions_country = 'succeeded'
                state.countries.map((item) => {
                    if (item.id == action.payload.country_id) {
                        item.competitions =
                            action.payload.data.data.competitions
                    }
                })
            } else {
                state.status.competitions_country = 'failed'
            }
        })
        .addCase(getCompetitionsByCountry.rejected, (state, action) => {
            state.status.competitions_country = 'failed'
        })
        .addCase(setAuthModal, (state, action: any) => {
            // "mutate" the array by calling push()
            state.authModal = action?.payload as any
        })
        .addCase(getFavorites.pending, (state, action) => {
            state.status.favorites = 'loading'
        })
        .addCase(getFavorites.fulfilled, (state, action) => {
            state.status.favorites = 'succeeded'
            if (action.payload.status == 'ok') {
                state.favorites = action.payload.data
            } else {
                // default state
                state.favorites = {
                    team: [],
                    match: [],
                    competition: [],
                    bookmaker: [],
                }
            }
            localStorage.setItem('favorites', JSON.stringify(state.favorites))
        })
        .addCase(getFavorites.rejected, (state, action) => {
            state.status.favorites = 'failed'
        })
        .addCase(setFavorites, (state, action: any) => {
            // "mutate" the array by calling push()
            state.favorites = action?.payload as any
            // update localstorage
            localStorage.setItem('favorites', JSON.stringify(action.payload))
        })
        .addCase(addFavorites.pending, (state, action) => {
            state.status.addFavorite = 'loading'
        })
        .addCase(addFavorites.fulfilled, (state, action) => {
            if (action.payload.status == 'ok') {
                state.status.addFavorite = 'succeeded'
                // update localstorage
            } else {
                state.status.addFavorite = 'failed'
            }
        })
        .addCase(addFavorites.rejected, (state, action) => {
            state.status.addFavorite = 'failed'
        })
        .addCase(removeFavorites.pending, (state, action) => {
            state.status.removeFavorite = 'loading'
        })
        .addCase(removeFavorites.fulfilled, (state, action) => {
            if (action.payload.status == 'ok') {
                state.status.removeFavorite = 'succeeded'
                // update localstorage
            } else {
                state.status.removeFavorite = 'failed'
            }
        })
        .addCase(removeFavorites.rejected, (state, action) => {
            state.status.removeFavorite = 'failed'
        })
        .addCase(setFull, (state, action: any) => {
            // "mutate" the array by calling push()
            state.full = action?.payload as any
        })
        .addCase(setModal, (state, action: any) => {
            // "mutate" the array by calling push()
            state.bestOfferModal = action?.payload as any
        })
)

export default appReducer
