import {createApi} from '@reduxjs/toolkit/query/react'
import {HYDRATE} from 'next-redux-wrapper'
import {axiosBaseQuery} from './baseApi'
import {buildQueryWithHashParam} from './helpers';
import {IFetchSuccess, IFetchWithPollingAndMetaSuccess, Match, OddsFetched} from './types';

export const matchApi = createApi({
    reducerPath: 'apiMatch',
    baseQuery: axiosBaseQuery({
        baseUrl: 'https://api.oddsocean.com/v1/',
    }),
    extractRehydrationInfo(action, { reducerPath }) {
        if (action.type === HYDRATE) {
            return action.payload[reducerPath]
        }
    },
    tagTypes: [],
    keepUnusedDataFor: 0,
    endpoints: (builder) => ({
        getMatch: builder.query<IFetchWithPollingAndMetaSuccess<Match>, any>({
            queryFn: async ({ id, locale }: any, _api, _extraOptions, baseQuery) => {
                return buildQueryWithHashParam(
                    {
                        url: `match${id ? '/' + id : ''}?language=${locale}`,
                        method: 'get',
                    },
                    'apiMatch',
                    'getMatch',
                    _api,
                    baseQuery,
                )
            },
        }),
        getSeries: builder.query({
            query: ({ id, locale }: any) => ({
                url: `series/${id}?language=${locale}`,
                method: 'get',
            }),
        }),
        getOdds: builder.query<IFetchSuccess<OddsFetched>, any>({
            queryFn: (payload: any, _api, _extraOptions, baseQuery) => {
                let addUrl = ''
                if (payload.odd_type)
                    addUrl += `/${payload.odd_type}/${payload.period}`

                if (payload.odd_type && payload.handicap)
                    addUrl += `/${payload.handicap}`

                return buildQueryWithHashParam(
                    {
                        url: `odds/${payload.id}/${payload.type}${addUrl}?language=${payload.locale}`,
                        method: 'get',
                    },
                    'apiMatch',
                    'getOdds',
                    _api,
                    baseQuery,
                )
            },
        }),
        getSurebets: builder.query({
            query: (params: any) => ({
                url: `surebets/${params.id}?language=${params.locale}`,
                method: 'get',
            }),
        }),
        getMatchDetails: builder.query({
            query: (params: any) => ({
                url: `matchdetails/${params.id}?language=${params.locale}`,
                method: 'get',
            }),
        }),
        getMatchForecasts: builder.query({
            query: (params: any) => ({
                url: `predictions${params.id ? '/'+params.id : ''}?language=${params.locale}`,
                method: 'get',
            }),
        }),
        getMatchEvents: builder.query({
            queryFn: (params: any, _api, _extraOptions, baseQuery) => {
                return buildQueryWithHashParam(
                    {
                        url: `matchincidents/${params.id}?language=${params.locale}`,
                        method: 'get',
                    },
                    'apiMatch',
                    'getMatchEvents',
                    _api,
                    baseQuery,
                )
            },
        }),
        getMatchLineups: builder.query({
            queryFn: (params: any, _api, _extraOptions, baseQuery) => {
                return buildQueryWithHashParam(
                    {
                        url: `matchlineups/${params.id}?language=${params.locale}${params.hash ? `&hash=${params.hash}` : ''}`,
                        method: 'get',
                    },
                    'apiMatch',
                    'getMatchLineups',
                    _api,
                    baseQuery,
                )
            },
        }),
        getMatchStandings: builder.query({
            queryFn: (params: any, _api, _extraOptions, baseQuery) => {
                return buildQueryWithHashParam(
                    {
                        url: `matchstandings/${params.id}?language=${params.locale}`,
                        method: 'get',
                    },
                    'apiMatch',
                    'getMatchStandings',
                    _api,
                    baseQuery,
                )
            },
        }),
    }),
})

// Export hooks for usage in functional components
export const {
    useGetOddsQuery,
    useGetMatchQuery,
    useGetMatchLineupsQuery,
    useGetMatchEventsQuery,
    useGetMatchDetailsQuery,
    useGetSurebetsQuery,
    useGetSeriesQuery,
    useGetMatchForecastsQuery,
    useGetMatchStandingsQuery,
} = matchApi

// export endpoints for use in SSR
export const {
    getMatchForecasts,
    getMatch,
    getSeries,
    getOdds,
    getSurebets,
    getMatchDetails,
    getMatchEvents,
    getMatchLineups,
    getMatchStandings,
} = matchApi.endpoints
