import { useEffect } from 'react'
import { Router } from 'next/router'
import * as NProgress from 'nprogress'

type Props = {
    /**
     * The color of the bar.
     * @default "#29D"
     */
    color?: string;
    /**
     * The start position of the bar.
     * @default 0.3
     */
    startPosition?: number;
    /**
     * The stop delay in milliseconds.
     * @default 200
     */
    stopDelayMs?: number;
    /**
     * The height of the bar.
     * @default 3
     */
    height?: number;
    /**
     * Whether to show the bar on shallow routes.
     * @default true
     */
    showOnShallow?: boolean;
    /**
     * The nonce attribute to use for the `style` tag.
     * @default undefined
     */
    nonce?: string;
    /**
     * Debounce the progress bar so it only shows up after a delay.
     * Useful for fast navigations.
     */
    debounce?: number;

    /**
     * Use your custom CSS tag instead of the default one.
     * This is useful if you want to use a different style or minify the CSS.
     * @default (css) => <style nonce={nonce}>{css}</style>
     */
    transformCSS?: (css: string) => JSX.Element;
}

export const RouteProgressBar = ({
    color = '#ffdc21',
    startPosition = 0.3,
    stopDelayMs = 200,
    height = 3,
    showOnShallow = true,
    nonce,
    debounce = 0,
    transformCSS = (css) => <style nonce={nonce}>{css}</style>,
}: Props) => {
    let timer: NodeJS.Timeout | null = null;
    let debounceTimer: NodeJS.Timeout | null = null;
    let isStarted = false;

    useEffect(() => {
        NProgress.configure({
            showSpinner: false,
        });

        const routeChangeStart = (_: string, { shallow }: { shallow: boolean; }) => {
            if (shallow && !showOnShallow) return;

            if (debounceTimer) {
                clearTimeout(debounceTimer);
            }

            debounceTimer = setTimeout(() => {
                isStarted = true;
                NProgress.set(startPosition);
                NProgress.start();
            }, debounce);
        };

        const routeChangeEnd = (_: string, { shallow }: { shallow: boolean }) => {
            if (debounceTimer && !isStarted) {
                clearTimeout(debounceTimer);
                return;
            }

            if (shallow && !showOnShallow) return;

            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                isStarted = false;
                NProgress.done(true);
            }, stopDelayMs);
        };

        const routeChangeError = (_err: Error, _url: string, { shallow }: { shallow: boolean; }) => {
            if (debounceTimer && !isStarted) {
                clearTimeout(debounceTimer);
                return;
            }

            if (shallow && !showOnShallow) return;

            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                isStarted = false;
                NProgress.done(true);
            }, stopDelayMs);
        };

        Router.events.on('routeChangeStart', routeChangeStart);
        Router.events.on('routeChangeComplete', routeChangeEnd);
        Router.events.on('routeChangeError', routeChangeError);

        return () => {
            Router.events.off('routeChangeStart', routeChangeStart);
            Router.events.off('routeChangeComplete', routeChangeEnd);
            Router.events.off('routeChangeError', routeChangeError);
        };
    }, []);

    return transformCSS(`
    #nprogress {
      pointer-events: none;
    }
    #nprogress .bar {
      background: ${color};
      position: fixed;
      z-index: 9999;
      top: 0;
      left: 0;
      width: 100%;
      height: ${height}px;
    }
    #nprogress .peg {
      display: block;
      position: absolute;
      right: 0px;
      width: 100px;
      height: 100%;
      box-shadow: 0 0 10px ${color}, 0 0 5px ${color};
      opacity: 1;
      -webkit-transform: rotate(3deg) translate(0px, -4px);
      -ms-transform: rotate(3deg) translate(0px, -4px);
      transform: rotate(3deg) translate(0px, -4px);
    }
    #nprogress .spinner {
      display: block;
      position: fixed;
      z-index: 1031;
      top: 15px;
      right: 15px;
    }
    #nprogress .spinner-icon {
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      border: solid 2px transparent;
      border-top-color: ${color};
      border-left-color: ${color};
      border-radius: 50%;
      -webkit-animation: nprogresss-spinner 400ms linear infinite;
      animation: nprogress-spinner 400ms linear infinite;
    }
    .nprogress-custom-parent {
      overflow: hidden;
      position: relative;
    }
    .nprogress-custom-parent #nprogress .spinner,
    .nprogress-custom-parent #nprogress .bar {
      position: absolute;
    }
    @-webkit-keyframes nprogress-spinner {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes nprogress-spinner {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `);
}