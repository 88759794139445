import {ObjectValues} from '../../types';
import { Bookmaker } from './bookmaker'

export type MatchesType = 'all' | 'upcoming' | 'finished' | 'live' | 'fav'

export type GetMatchesParams = {
    sport: any,
    oddType: any,
    type: any,
    date: string,
    timePeriod?: MatchesTimePeriodValue,
    locale: any,
    competitions?: any,
    competition_id?: any,
}

/** Matches Time Period */

export const MATCHES_TIME_PERIOD = {
    ONE_HOUR: 'next-1-hours',
    THREE_HOURS: 'next-3-hours',
    TWELVE_HOURS: 'next-12-hours',
} as const

type MatchesTimePeriod = ObjectValues<typeof MATCHES_TIME_PERIOD>

export type MatchesTimePeriodValue = MatchesTimePeriod | null

/** Odds popup */

export type OddsPopupParams = {
    matchId: number,
    oddType: string,
    oddSelect: string,
    period: string | null,
    handicap: number | null,
}

export type OddsPopupFetched = {
    bookmakers: {
        bookmaker: Bookmaker,
        display: string,
    }[],
    maker: {
        display: string,
        value: number,
    },
}