import React from 'react'
import clsx from 'clsx';
import Image, {ImageProps} from 'next/image';
import {getLogoUrl} from './helpers';
import {LogoSize, LogoType} from './types';

type Props = {
    type: LogoType,
    size: LogoSize,
    logoId: number | string,
    dimension?: number,
    alt?: string,
    withBorder?: boolean,
    className?: string,
} & Omit<ImageProps, 'src' | 'alt'>

export const Logo = ({
    type,
    size,
    logoId,
    dimension,
    alt = '',
    withBorder,
    className,
    ...props
}: Props) => (
    withBorder
        ? (
            <div
                className={clsx('Logo__contianer', className)}
                style={{
                    width: dimension || size,
                    height: dimension || size,
                }}
            >
                <Image
                    className='Logo Logo--with-border'
                    src={getLogoUrl(type, size, logoId)}
                    width={dimension || size}
                    height={dimension || size}
                    alt={alt}
                    {...props}
                />
            </div>
        )
        : (
            <Image
                className={clsx('Logo',  className)}
                src={getLogoUrl(type, size, logoId)}
                width={dimension || size}
                height={dimension || size}
                alt={alt}
                {...props}
            />
        )
)