const moment = require('moment');

// @ts-check

const Cookies = require('js-cookie');
/**
 * @type {import('next-i18next').UserConfig}
 */

moment.updateLocale('en', {
  calendar: {
    lastDay: '[Yesterday at] HH:mm',
    sameDay: '[Today at] HH:mm',
    nextDay: '[Tomorrow at] HH:mm',
    lastWeek: '[last] dddd [at] HH:mm',
    nextWeek: 'dddd [at] HH:mm',
    sameElse: 'L'
  }
});

module.exports = {
  // https://www.i18next.com/overview/configuration-options#logging
  debug: false,
  i18n: {
    locales: ['default', 'ru', 'en', 'fr', 'de', 'es', 'it', 'bg', 'cs', 'da', 'el', 'hu', 'nl', 'no', 'pl', 'pt-br', 'pt-pt', 'ro', 'sk', 'sr', 'sv', 'tr', 'uk'],
    defaultLocale: 'default',
    localeDetection: false,
  },
  trailingSlash: true,
  fallbackLng: ['de', 'en', 'es', 'fr', 'hu', 'it', 'pl', 'ro', 'ru'],
  /** To avoid issues when deploying to some paas (vercel...) */
  // ns: ['common'],
  localePath:
    typeof window === 'undefined'
      ? require('path').resolve('./public/locales')
      : '/public/locales',
  reloadOnPrerender: process.env.NODE_ENV === 'development',
  /**
   * @link https://github.com/i18next/next-i18next#6-advanced-configuration
   */
  //saveMissing: true,
  //strictMode: true,
  serializeConfig: false,

  interpolation: {
    format: function (value, format, lng, options) {
      if (value instanceof Date) {

        let formatAdd = ''

        const str = format;
        const result = str.replace(/{{|}}/g, '').split(/\s*,\s*(?![^\[]*\])/);
        format = result[0];
        if (result[1]) {
          formatAdd = result[1];
          if (formatAdd != undefined) {
            if (moment(value * 1000).year() == moment(new Date()).year()) {
              formatAdd = formatAdd.replace('YYYY', '');
            }
          }
        }

        switch (format) {
          case "countdown":
            var diffTime = value.getTime() - options.dateNow.getTime();
            var duration = moment.duration(diffTime, 'milliseconds');
            let days = duration.days();
            if (days > 0) {
              return duration.locale(lng).humanize();
            }
            days = days > 0 ? days + `${formatAdd}. ` : '';

            let hours = duration.hours();
            let minutes = duration.minutes();
            let seconds = duration.seconds();

            const _hours = hours > 0 ? hours + ':' : '';
            const _minutes = (hours > 0 && minutes < 10 ? '0' + minutes : minutes) + ':'
            const _seconds = (minutes > 0 && seconds < 10) ? '0' + seconds : seconds;
            return days + _hours + _minutes + _seconds;
          case "calendar":
            if (options.format)
              return moment(value * 1000).locale(lng).calendar(options.format);
            else
              return moment(value * 1000).locale(lng).calendar();
          case "format":
            return moment(value * 1000).locale(lng).format(formatAdd);
          default:
            return moment(value * 1000).locale(lng).format(format);
        }
      }


      return value;
    }
  },
  //react: { useSuspense: false }
  onPreInitI18next: (i18n) =>
    i18n.on('languageChanged', (lng) => {
      if (typeof window !== 'undefined') {
        localStorage.setItem('lng', lng);
        // for server side
        Cookies.set('language', lng);
      }
    })
};  