import { createApi } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'
import { axiosBaseQuery } from './baseApi'
import {
    Competition,
    CompetitionFetchParams,
    CompetitionStandings,
    CompetitionStandingsFetchParams,
    CountriesCompetitions,
    CountryCompetitions,
    CountryCompetitionsFetchParams,
    IFetchSuccess,
    LanguageParam,
    RatingUefaFetched,
    RatingUefaFetchParams,
} from './types'

export const competitionsApi = createApi({
    reducerPath: 'apiCompetitions',
    baseQuery: axiosBaseQuery({
        baseUrl: 'https://api.oddsocean.com/v1/',
    }),
    extractRehydrationInfo(action, { reducerPath }) {
        if (action.type === HYDRATE) {
            return action.payload[reducerPath]
        }
    },
    tagTypes: [],
    endpoints: (builder) => ({
        getCountries: builder.query<IFetchSuccess<CountriesCompetitions>, LanguageParam>({
            query: ({ locale }) => ({
                url: `countries?language=${locale}`,
                method: 'get',
            }),
        }),
        getCountryCompetitions: builder.query<IFetchSuccess<CountryCompetitions>, CountryCompetitionsFetchParams>({
            query: ({ sport, country, locale }) => ({
                url: `countrycompetitions/${sport}/${country}?language=${locale}`,
                method: 'get',
            }),
        }),
        getCompetition: builder.query<IFetchSuccess<Competition>, CompetitionFetchParams>({
            query: ({ id, locale, season }) => ({
                url: `competition/${id}/${season != '' ? season : ''}?language=${locale}`,
                method: 'get',
            }),
            keepUnusedDataFor: 0,
        }),
        updateCountries: builder.mutation({
            query: ({ sportId, countryId, locale }) => ({
                url: `countrycompetitions/${sportId}/${countryId}?language=${locale}`,
                method: 'GET',
            }),
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                const result = await queryFulfilled
                const competitions = result.data.data.competitions

                if (!result) {
                    // обработка ошибки
                } else {
                    dispatch(
                        competitionsApi.util.updateQueryData(
                            'getCountries',
                            {
                                locale: arg.locale,
                            },
                            (data) => {
                                let indexSearch = 0

                                for (
                                    let i = 0;
                                    i < data.data.countries.length;
                                    i++
                                ) {
                                    if (
                                        data.data.countries[i].id ==
                                        arg.countryId
                                    ) {
                                        indexSearch = i
                                        break
                                    }
                                }

                                return {
                                    ...data,
                                    data: {
                                        countries: data.data.countries.map(
                                            (item: any, index: number) =>
                                                indexSearch == index
                                                    ? { ...item, competitions }
                                                    : item
                                        ),
                                    },
                                }
                            }
                        )
                    )
                }
            },
        }),
        getCompetitionStandings: builder.query<IFetchSuccess<CompetitionStandings>, CompetitionStandingsFetchParams>({
            query: ({ competitionId, season, locale }) => ({
                url: `competitionstandings/${competitionId}/${season}?language=${locale}`,
                method: 'get',
            }),
        }),
        getRatingUefa: builder.query<IFetchSuccess<RatingUefaFetched>, RatingUefaFetchParams>({
            query: ({ type, locale }) => ({
                url: `ranking${type ? '/'+type : ''}?language=${locale}`,
                method: 'get',
            }),
        }),
    }),
})

export const {
    useGetCountriesQuery,
    useUpdateCountriesMutation,
    useGetCompetitionQuery,
    useGetCountryCompetitionsQuery,
    useGetCompetitionStandingsQuery,
    useGetRatingUefaQuery,
} = competitionsApi

export const { getCountries, getCompetition } = competitionsApi.endpoints
