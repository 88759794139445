import { RootState } from '../../store'
import { createSelector } from '@reduxjs/toolkit'

export const selectMatch = (state: RootState) => state.matches.match
export const selectMatches = (state: RootState) => state.matches.matches
export const selectMatchDetails = (state: RootState) =>
    state.matches.matchDetails
export const selectMatchEvents = (state: RootState) => state.matches.matchEvents
export const selectMatchOdds = (state: RootState) => state.matches.odds
export const selectForecasts = (state: RootState) => state.matches.forecasts

export const matchDetailsSelector = createSelector(
    selectMatchDetails,
    (state) => state
)

export const forecastsSelector = createSelector(
    selectForecasts,
    (state) => state
)

export const matchEventsSelector = createSelector(
    selectMatchEvents,
    (state) => state
)

export const matchSelector = createSelector(selectMatch, (state) => state)

export const matchesSelector = createSelector(selectMatches, (state) => state)

export const matchOddsSelector = createSelector(
    selectMatchOdds,
    (state) => state
)

const selectMatchStandings = (s: RootState) => s.matches.standings
export const matchStandingsSelector = createSelector(
    selectMatchStandings, (s) => s
)

export const getMatchIdSelected = createSelector(
    (s: RootState) => s.matches.matchIdSelected,
    (s) => s,
)