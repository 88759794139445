import { createReducer } from '@reduxjs/toolkit'
import { getCompetitionsTop, setSeason } from './actions'

type StatusAction = 'idle' | 'loading' | 'succeeded' | 'failed'

export type LeaguesState = {
    top: []
    status: {
        top: StatusAction
    },
    season: string,
}

const initialState: LeaguesState = {
    top: [],
    status: {
        top: 'idle',
    },
    season: '',
}

export const competitionsReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(getCompetitionsTop.pending, (state, action) => {
            state.status.top = 'loading'
        })
        .addCase(getCompetitionsTop.fulfilled, (state, action) => {
            if (action.payload.status == 'ok') {
                state.status.top = 'succeeded'
                state.top = action.payload.data.competitions
            } else {
                state.status.top = 'failed'
            }
        })
        .addCase(getCompetitionsTop.rejected, (state, action) => {
            state.status.top = 'failed'
        })
        .addCase(setSeason, (state, action: any) => {
            state.season = action?.payload
        })
)

export default competitionsReducer
