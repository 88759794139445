import React, { useRef, useState } from 'react'
import Image from 'next/image'
import useOutsideAlerter from '../hooks/outsideClick'
import global from '../public/img/global.svg'
import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'

type Props = {
    // Add custom props here
    direction?: string
    class?: string
}

export default function LanguageSwitcher(props: Props) {
    const { i18n } = useTranslation()
    const router = useRouter()

    const [open, setOpen] = useState(false)

    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef, () => {
        setOpen(false)
    })

    const onToggleLanguageClick = (newLocale: string) => {
        setOpen(false)
        i18n.changeLanguage(newLocale)
        router.replace(router.asPath, undefined, {locale: newLocale})
    }

    const onToggleModal = (e: MouseEvent<HTMLButtonElement>) => {
        setOpen(!open)
        e.preventDefault()
        return false
    }

    const languages = [
        { value: 'English', short: 'EN', key: 'en' },
        { value: 'Deutsch', short: 'DE', key: 'de' },
        { value: 'Español', short: 'ES', key: 'es' },
        { value: 'Français', short: 'FR', key: 'fr' },
        { value: 'Italiano', short: 'IT', key: 'it' },
        { value: 'Русский', short: 'RU', key: 'ru' },
        { value: 'Български', short: 'BG', key: 'bg' },
        { value: 'Čeština', short: 'CS', key: 'cs' },
        { value: 'Dansk', short: 'DA', key: 'da' },
        { value: 'Ελληνικά', short: 'EL', key: 'el' },
        { value: 'Magyar', short: 'HU', key: 'hu' },
        { value: 'Nederlands', short: 'NL', key: 'nl' },
        { value: 'Norsk', short: 'NO', key: 'no' },
        { value: 'Polski', short: 'PL', key: 'pl' },
        { value: 'Português-BR', short: 'PTBR', key: 'pt-br' },
        { value: 'Português-PT', short: 'PTPT', key: 'pt-pt' },
        { value: 'Română', short: 'RO', key: 'ro' },
        { value: 'Slovenčina', short: 'SK', key: 'sk' },
        { value: 'Srpski', short: 'SR', key: 'sr' },
        { value: 'Svenska', short: 'SV', key: 'sv' },
        { value: 'Türkçe', short: 'TR', key: 'tr' },
        { value: 'Українська', short: 'UK', key: 'uk' },
    ]

    return (
        <div className={`wrapper wrapper--lng ${props.class}`} ref={wrapperRef}>
            <button
                onClick={(e) => onToggleModal(e)}
                className={`button button--bound button--small-size button--transparent-color ${
                    open ? 'button--active' : ''
                }`}
            >
                <Image loading="eager" src={global} alt="global" />
            </button>
            <div
                className={`language-switcher ${
                    props.direction === 'up' ? 'language-switcher--up' : ''
                }`}
                style={{ display: open ? 'grid' : 'none' }}
            >
                {languages.map((lng) => (
                    <button
                        key={`language-switcher-key-${lng.key}`}
                        className={`language-switcher__button ${
                            i18n.language === lng.key
                                ? 'language-switcher__button--active'
                                : ''
                        }`}
                        onClick={() => onToggleLanguageClick(lng.key)}
                    >
                        {lng.value}
                    </button>
                ))}
            </div>
        </div>
    )
}
