import React, { useEffect } from 'react'

export default function useOutsideAlerter(
    ref: React.RefObject<HTMLElement>,
    cb: Function,
    refAdditional?: React.RefObject<HTMLElement>,
) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: Event) {
            // Sometimes you'll need to track click on another node (like absolute dropdown list)
            if (refAdditional?.current && refAdditional.current.contains(event.target as HTMLElement)) return

            if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
                cb()
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref])
}
