import { RootState } from '../../store'
import { createSelector } from '@reduxjs/toolkit'

export const selectBookmakersRating = (state: RootState) =>
    state.bookmakers.rating

export const bookmakersRatingSelector = createSelector(
    selectBookmakersRating,
    (state) => state
)

export const bookmakersRatingFetchedSelector = createSelector(
    (state: RootState) => state.bookmakers,
    (state) => state
)
