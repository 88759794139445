// import { useRouter } from 'next/router'
import React, { useRef, useState } from 'react'
import Image from 'next/image'

import useOutsideAlerter from '../hooks/outsideClick'
import gamb from '../public/img/gamb.svg'
import { MouseEvent } from 'react'

type Props = {
    // Add custom props here
    direction?: string
    class?: string
}

export default function MenuMobile(props: Props) {
    const [open, setOpen] = useState(false)

    // const router = useRouter()
    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef, () => {
        setOpen(false)
    })

    const onToggleModal = (e: MouseEvent<HTMLButtonElement>) => {
        setOpen(!open)
        return false
        e.preventDefault()
    }

    return (
        <div
            className={`wrapper wrapper--gamb ${props.class}`}
            ref={wrapperRef}
        >
            <button
                onClick={(e) => onToggleModal(e)}
                className={`button button--bound button--small-size button--transparent-color ${
                    open ? 'button--active' : ''
                }`}
            >
                <Image src={gamb} alt="global" />
            </button>
        </div>
    )
}
