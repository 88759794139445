import {GetServerSidePropsContext} from 'next';
import {HEADER_IS_BOT} from './const';

export const isRequestFromBot = (context:  GetServerSidePropsContext): boolean => {
    const resHeaders = context.res.getHeaders()
    return !!resHeaders[HEADER_IS_BOT]
}

export const isRequestFromGuest = (commonProps: any) => {
    return !commonProps.parsedCookies ||
        (commonProps.parsedCookies && commonProps.parsedCookies['auth-token'] == undefined)
}