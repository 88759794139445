import { createReducer } from '@reduxjs/toolkit'
import { getRating } from './actions'

type StatusAction = 'idle' | 'loading' | 'succeeded' | 'failed'

export type VirtalBookmakerState = {
    rating: []
    status: {
        rating: StatusAction
    }
}

const initialState: VirtalBookmakerState = {
    rating: [],
    status: {
        rating: 'idle',
    },
}

export const virtulBookmakerReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(getRating.pending, (state, action) => {
            state.status.rating = 'loading'
        })
        .addCase(getRating.fulfilled, (state, action) => {
            state.status.rating = 'succeeded'
            state.rating = action?.payload?.data?.rating
        })
        .addCase(getRating.rejected, (state, action) => {
            state.status.rating = 'failed'
        })
)

export default virtulBookmakerReducer
