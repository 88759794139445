import { createReducer } from '@reduxjs/toolkit'
import {
    clearDataInStore,
    getBothTeamsMatchResults,
    getFirstTeamMatchResults,
    getSecondTeamMatchResults,
} from './actions'

type StatusAction = 'loading' | 'succeeded' | 'failed'

export type ResultsTeamState = {
    currentType: string
    firstTeamResults: any[]
    secondTeamResults: any[]
    bothTeamsResults: any[]
    status: StatusAction
}

const initialState: ResultsTeamState = {
    currentType: 'all',
    firstTeamResults: null,
    secondTeamResults: null,
    bothTeamsResults: null,
    status: 'loading',
}

export const resultsTeamReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(getFirstTeamMatchResults.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(getFirstTeamMatchResults.fulfilled, (state, { payload }) => {
            state.firstTeamResults = payload?.data?.resultsteam
            state.status = 'succeeded'
        })
        .addCase(getFirstTeamMatchResults.rejected, (state) => {
            state.status = 'failed'
        })
        .addCase(getSecondTeamMatchResults.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(getSecondTeamMatchResults.fulfilled, (state, { payload }) => {
            state.secondTeamResults = payload?.data?.resultsteam
            state.status = 'succeeded'
        })
        .addCase(getSecondTeamMatchResults.rejected, (state) => {
            state.status = 'failed'
        })
        .addCase(getBothTeamsMatchResults.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(getBothTeamsMatchResults.fulfilled, (state, { payload }) => {
            state.bothTeamsResults = payload?.data?.resultsteam
            state.status = 'succeeded'
        })
        .addCase(getBothTeamsMatchResults.rejected, (state) => {
            state.status = 'failed'
        })
        .addCase(clearDataInStore.fulfilled, (state) => {
            state.bothTeamsResults = []
            state.firstTeamResults = []
            state.secondTeamResults = []
        })
)

export default resultsTeamReducer
