import st from './Header.module.scss'
import Link from 'next/link'
import Image from 'next/image'
import Dropdown from '../Dropdown'
import etc from '../../public/img/etc.svg'
import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { CompetitionTop } from '../../api'

const linksAmountForDropdown = 4

type Props = {
    competitionsTop: CompetitionTop[],
}

type CompetitionToRender = {
    value: number,
    title: string,
    url: string,
    ico: string,
    onScreen: boolean,
}

export const HeaderCompetitionLinks = ({competitionsTop}: Props) => {
    const { i18n } = useTranslation('common')
    const refLinksContainer = useRef<HTMLDivElement>(null)
    const [preparedListCompetitions, setPreparedListCompetitions] = useState<CompetitionToRender[]>([])
    const [areLinksPreparing, setAreLinksPreparing] = useState(true)

    // Check for overflowing links, hide excess and show dropdown, if need
    useEffect(() => {
        function initLinks() {
            setPreparedListCompetitions(competitionsTop.map((item, index) => ({
                value: index,
                title: item.title,
                url: item.url,
                ico: `https://i.oddsocean.com/img/ocean/60x60/compbw/${item.logo}.png`,
                onScreen: true,
            })))
        }

        function handleResize() {
            if (!refLinksContainer.current ||
                refLinksContainer.current.scrollWidth === refLinksContainer.current.clientWidth
            ) {
                initLinks()
                return
            }

            const containerMaxWidth = refLinksContainer.current.getBoundingClientRect().right
            const dropdownWidth = 48

            let indexToHide: number | null = null
            const linksElements = Array.from(refLinksContainer.current.children)

            // Get first overflowing element
            linksElements.every((element, index) => {
                if (element.getBoundingClientRect().right > containerMaxWidth) {
                    indexToHide = index
                    return false
                }
                return true
            })

            if (indexToHide === null) return

            // If show dropdown, check last visible element fits container
            const showDropdown = indexToHide <= linksAmountForDropdown
            if (showDropdown && indexToHide > 0) {
                const isLastVisibleElementOverflows = linksElements[indexToHide - 1]
                    .getBoundingClientRect().right > (containerMaxWidth - dropdownWidth)
                if (isLastVisibleElementOverflows) indexToHide -= 1
            }

            setPreparedListCompetitions((s) => s.map((item, index) => ({
                ...item,
                onScreen: index < indexToHide,
            })))
        }

        // Overflow check is possible only for all links rendered in container,
        // so they're re-rendered for each links reload / window width change
        function setLinksList() {
            setAreLinksPreparing(true)
            initLinks()
            setTimeout(() => {
                handleResize()
                setAreLinksPreparing(false)
            })
        }

        setLinksList()

        let lastWidth = window.innerWidth;
        function checkWindowResize() {
            const currentWidth = window.innerWidth;

            if (currentWidth === lastWidth) return

            lastWidth = currentWidth;
            setLinksList()
        }

        window.addEventListener('resize', checkWindowResize)
        return () => {
            window.removeEventListener('resize', checkWindowResize)
        }
    }, [i18n.language])

    const linksToRender = preparedListCompetitions.filter((i) => i.onScreen)
    const linksHidden = preparedListCompetitions.filter((i) => !i.onScreen)
    const showDropdown = linksToRender.length <= linksAmountForDropdown && linksHidden.length > 0

    return (
        <div
            className={clsx(
                'flex flex-justify',
                areLinksPreparing && st.linksContainerLoading,
            )}
            ref={refLinksContainer}
        >
            {linksToRender.map((item, index) => (
                <Link
                    key={index}
                    className='button button--filter button__ico-filter--header'
                    href={item.url}
                >
                    <Image
                        src={item.ico}
                        alt={item.title}
                        loading={index <= 1 ? 'eager' : 'lazy'}
                        width={30}
                        height={30}
                        className='button__ico-filter'
                    />
                    <span className='submenu__title-ico text-with-overflow'>
                        {item.title}
                    </span>
                </Link>
            ))}
            {showDropdown && (
                <Dropdown
                    className={st.dropdownLeagueLinks}
                    classNameList='sports-list-dropdown'
                    header={
                        <button className='button button--filter button--filter-etc'>
                            <Image
                                src={etc}
                                loading='eager'
                                alt='Dropdown menu'
                                width={40}
                                height={40}
                                className='button__ico-filter button__ico-filter--nospace'
                            />
                        </button>
                    }
                    options={linksHidden as any}
                />
            )}
        </div>
    )
}