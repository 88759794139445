import { createApi } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'
import { axiosBaseQuery } from './baseApi'
import {
    BonusesFetched,
    CompetitionPlayoffs,
    GetMatchesParams,
    IFetchSuccess,
    LanguageParam,
    CompetitionsTopFetched, OddsPopupParams, OddsPopupFetched,
} from './types'
import { buildQueryWithHashParam, formatMatchesFilerTimeForServer } from './helpers'

export const matchesApi = createApi({
    reducerPath: 'api',
    baseQuery: axiosBaseQuery({
        baseUrl: 'https://api.oddsocean.com/v1/',
    }),
    extractRehydrationInfo(action, { reducerPath }) {
        if (action.type === HYDRATE) {
            return action.payload[reducerPath]
        }
    },
    tagTypes: [],
    endpoints: (builder) => ({
        getTopCompetitions: builder.query<IFetchSuccess<CompetitionsTopFetched>, LanguageParam>({
            query: ({ locale }) => ({
                url: `topcompetitions?language=${locale}`,
                method: 'get',
            }),
        }),
        getParseUrl: builder.query({
            query: ({ url, locale  }: any) => ({
                url: `parseurl/?url=${url}&language=${locale}`,
                method: 'get',
            }),
        }),
        getBonus: builder.query<IFetchSuccess<BonusesFetched>, string>({
            query: (locale) => ({
                url: `bonus/?language=${locale}`,
                method: 'get',
            }),
        }),
        getTopOffers: builder.query({
            query: ({ locale }: any) => ({
                url: `oddspopup/?language=${locale}`,
                method: 'post',
                data: {
                    'match_id': 9013711,
                    'odd_type': '3w',
                    'odd_select': 'x',
                    'period': null,
                    'handicap': null,
                },
            }),
        }),
        getOddsPopup: builder.query<IFetchSuccess<OddsPopupFetched>, OddsPopupParams & LanguageParam>({
            query: ({
                matchId,
                oddType,
                oddSelect,
                period,
                handicap,
                locale,
            }) => ({
                url: `oddspopup?language=${locale}`,
                method: 'post',
                data: {
                    match_id: matchId,
                    odd_type: oddType,
                    odd_select: oddSelect,
                    period,
                    handicap,
                },
            }),
        }),
        getMatches: builder.query({
            queryFn: ({
                sport,
                oddType,
                type,
                date,
                timePeriod,
                locale,
                competitions,
                competition_id,
            }: GetMatchesParams, _api, _extraOptions, baseQuery) => {
                return buildQueryWithHashParam(
                    {
                        url: `matches/${sport}/${oddType}/${type}/${formatMatchesFilerTimeForServer(timePeriod) ?? date}${competition_id ? '/' + competition_id : ''
                        }?language=${locale}${competitions && competitions.length > 0 ? `&competitions=${competitions.join(',')}` : ''}`,
                        method: 'get',
                        keepUnusedDataFor: 0,
                    },
                    'api',
                    'getMatches',
                    _api,
                    baseQuery,
                )
            },
        }),
        updateCompetitionMatches: builder.mutation({
            query: ({
                sport = '1',
                oddType = '3w',
                type = 'all',
                date = '2023-04-19',
                competitionId = null,
                locale,
            }) => ({
                url: `matches/${sport}/${oddType}/${type}/${date}/${competitionId}?language=${locale}`,
                method: 'GET',
            }),
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                const result = await queryFulfilled
                const matches = result.data.data.competitions[0].matches

                if (!result) {
                    // обработка ошибки
                } else {
                    console.log('competition loaded', matches)

                    dispatch(
                        matchesApi.util.updateQueryData(
                            'getMatches',
                            {
                                sport: arg.sport,
                                oddType: arg.oddType,
                                type: arg.type,
                                date: arg.date,
                                locale: arg.locale,
                                competitions: arg.competitions,
                            },
                            (data) => {
                                let indexSearch = 0

                                for (
                                    let i = 0;
                                    i < data.data.competitions.length;
                                    i++
                                ) {
                                    if (
                                        data.data.competitions[i].id ==
                                        arg.competitionId
                                    ) {
                                        indexSearch = i
                                        break
                                    }
                                }

                                const updated = {
                                    ...data,
                                    data: {
                                        ...data.data,
                                        competitions:
                                            data.data.competitions.map(
                                                (item: any, index: number) =>
                                                    indexSearch == index
                                                        ? { ...item, matches }
                                                        : item
                                            ),
                                    },
                                }

                                return updated
                            }
                        )
                    )
                }
            },
        }),
        getCompetitionPlayoff: builder.query<IFetchSuccess<CompetitionPlayoffs>, any>({
            query: ({ competitionId, season, locale }: any) => ({
                url: `competitionplayoff/${competitionId}/${season}?language=${locale}`,
                method: 'get',
            }),
        }),
    }),
})

// Export hooks for usage in functional components
export const {
    useGetMatchesQuery,
    useUpdateCompetitionMatchesMutation,
    useGetBonusQuery,
    useGetTopCompetitionsQuery,
    useGetCompetitionPlayoffQuery,
    useGetOddsPopupQuery,
} = matchesApi

// export endpoints for use in SSR
export const {
    getMatches,
    getParseUrl,
    getBonus,
    getTopCompetitions,
} = matchesApi.endpoints
