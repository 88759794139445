import { createAsyncThunk, createAction } from '@reduxjs/toolkit'

import axios from '../../plugins/axios'

export type PayloadLogin = {
    identifier: string
    password: string
}

export type PayloadRegister = {
    login: string
    email: string
    password: string
    odds_format: string
    favorites: string
}
export const setCookies = createAction('set-cookies')
export const postLogin = createAsyncThunk(
    'auth/signin',
    async ({ identifier, password }: PayloadLogin) => {
        try {
            const response = await axios.post('auth', {
                identifier,
                password,
            })

            return response.data
        } catch (error: any) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                return error.response.data
            }
        }

    }
)

export const postRegister = createAsyncThunk(
    'auth/signup',
    async ({ login, email, password, odds_format }: PayloadRegister) => {
        const response = await axios.post('signup', {
            login,
            email,
            password,
            odds_format,
        })

        return response.data
    }
)

export const postLogout = createAsyncThunk('logout', async () => {
    const response = await axios.post('logout')

    return response.data
})
